import React, { createContext, FC, useContext, useState } from 'react'

type SalesTaxState = 'inclusive' | 'exclusive'

export type SalesTaxContext = {
  type: SalesTaxState
  setType: (type: SalesTaxState) => void
}

const SalesTaxContext = createContext<SalesTaxContext>({
  type: 'exclusive',
  setType: () => console.warn('Sales Tax Context has not been initialized')
})

export const SalesTaxProvider: FC = ({ children }) => {
  const [salesTax, setSalesTax] = useState<SalesTaxState>('exclusive')

  const contextValue = {
    type: salesTax,
    setType: setSalesTax,
  }

  return (
    <SalesTaxContext.Provider value={contextValue}>
      {children}
    </SalesTaxContext.Provider>
  )
}

export const useSalesTax = () => useContext(SalesTaxContext)
