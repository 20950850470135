import '../styles/globals.css'

import { UserProvider } from '@auth0/nextjs-auth0'
import * as Fathom from 'fathom-client'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { SalesTaxProvider } from '../hooks'

function App({ Component, pageProps }: AppProps) {
  const router = useRouter()
  useEffect(() => {
    // Initialize Fathom when the app loads
    Fathom.load(process.env.NEXT_PUBLIC_FATHOM_CLIENT_ID ?? 'VUUHGJUA', {
      includedDomains: [
        'natverkspadel.com',
        'www.natverkspadel.com',
        'natverkspadel.se',
        'www.natverkspadel.se',
      ],
    })

    function onRouteChangeComplete() {
      Fathom.trackPageview()
    }
    // Record a pageview when route changes
    router.events.on('routeChangeComplete', onRouteChangeComplete)

    // Unassign event listener
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <UserProvider>
      <SalesTaxProvider>
        <Component {...pageProps} />
      </SalesTaxProvider>
    </UserProvider>
  )
}

export default App
